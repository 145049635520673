import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react'
import { ExternalLink } from 'react-feather'
// import { withRouter } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc'

class ResourceCategoryList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      modalShow: false,
      resources: []
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.resources.length !== this.props.resources.length ||
      nextProps.resources.length > 0
    ) {
      this.setState({ resources: nextProps.resources })
    }
  }

  render() {
    const { userStore } = this.props
    const role = userStore.role
    let { resources } = this.state
    let leftColResources = {}
    let rightColResources = {}
    leftColResources = resources.filter(resource => {
      if (role === 'appadmin') {
        return resource.fields.Column === 'Left' || !resource.fields.Column
      } else if (resource.fields && resource.fields.Roles) {
        return (
          (resource.fields.Column === 'Left' || !resource.fields.Column) &&
          (role === 'appadmin' ||
            resource.fields.Roles.includes(role.charAt(0).toUpperCase() + role.slice(1)) ||
            resource.fields.Roles.includes('All Roles'))
        )
      }
    })

    rightColResources = resources.filter(resource => {
      if (role === 'appadmin') {
        return resource.fields.Column === 'Right'
      } else if (resource.fields && resource.fields.Roles) {
        return (
          resource.fields.Column === 'Right' &&
          (role === 'appadmin' ||
            resource.fields.Roles.includes(role.charAt(0).toUpperCase() + role.slice(1)) ||
            resource.fields.Roles.includes('All Roles'))
        )
      }
    })

    return (
      <Box pt={{ xs: 5, md: 10 }} px={{ xs: 4, md: 6 }} pb={{ xs: 10, md: 15 }}>
        {leftColResources.length > 0 && (
          <Box className='resource-category__link-group'>
            {leftColResources.map(option => (
              <Typography variant='link' className='large' sx={{ mb: 2 }}>
                {option.fields.VideoUrl ? (
                  <a
                    style={{ fontFamily: 'inherit', fontSize: 'inherit' }}
                    href={option.fields.VideoUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {option.fields.Name} <ExternalLink size={13} />
                  </a>
                ) : (
                  <a
                    style={{ fontFamily: 'inherit', fontSize: 'inherit' }}
                    href={option.fields.PDF ? option.fields.PDF[0].url : '#'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {option.fields.Name}
                  </a>
                )}
              </Typography>
            ))}
          </Box>
        )}
        {rightColResources.length > 0 && (
          <Box className='resource-category__link-group'>
            {rightColResources.map(option => (
              <Typography variant='link' className='large' sx={{ mb: 2 }}>
                {option.fields.VideoUrl ? (
                  <a
                    style={{ fontFamily: 'inherit', fontSize: 'inherit' }}
                    href={option.fields.VideoUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {option.fields.Name} <ExternalLink size={13} />
                  </a>
                ) : (
                  <a
                    style={{ fontFamily: 'inherit', fontSize: 'inherit' }}
                    href={option.fields.PDF ? option.fields.PDF[0].url : '#'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {option.fields.Name}
                  </a>
                )}
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    )
  }
}

export default inject('userStore', 'resourceStore')(observer(withRouter(ResourceCategoryList)))
