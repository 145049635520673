import React, { Component, Fragment } from 'react'
import './resources.scss'
import ResourceHero from '../ui/molecules/ResourceHero.js'
import Hero from '../ui/organisms/Hero.js'
import ResourceCategoryList from '../ui/molecules/ResourceCategoryList.js'
import Layout from '../ui/template/Layout'
import { observer, inject } from 'mobx-react'
import { withRouter } from '../utils/customHoc'

const requestOptions = {
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_AIRTABLE_PAT}`,
  },
}

class ResourceCategory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      toastShow: false,
      resourceData: []
    }
  }

  fetchAllResources = (offset = '') => {
    const {params } = this.props
    const categoryId = params.categoryId
    let resourceUrl = 'https://api.airtable.com/v0/' + process.env.REACT_APP_AIRTABLE_APP_ID + '/Resources?view=Grid%20view'
    if(offset !== '') {
      resourceUrl='https://api.airtable.com/v0/' + process.env.REACT_APP_AIRTABLE_APP_ID + '/Resources?view=Grid%20view&offset=' + offset
    }
    fetch(resourceUrl, requestOptions)
    .then(response => response.json())
    .then(data => {
      let existingResources = this.state.resourceData
      let mergedResources = [...existingResources, ...data.records]
      if(data.offset) {
        this.fetchAllResources(data.offset)
      } else {
        let resourceData = mergedResources.filter(resource => {
          return resource.fields.Category ? resource.fields.Category.includes(categoryId) :''
        })
        this.setState({resourceData:resourceData})
      }

    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.')
    })
  }

  async componentWillMount() {
    await this.fetchAllResources()
  }

  render() {

    const { location, params, resourceStore, breadcrumbStore, basePath } = this.props
    const category = params.category.replace(/-+/g, ' ')
    const categoryId = params.categoryId
    let resourceData = []
    if(resourceStore.resources.length > 0) {
      resourceData = resourceStore.resources.filter(resource => {
        return resource.fields.Category ? resource.fields.Category.includes(categoryId) :''
      })
    } else {
      resourceData = this.state.resourceData
    }

    let orderedResources = [...resourceData]

    if(resourceStore.categories.length > 0) {
      let categoryResources = []
      let category = resourceStore.categories.filter(cat => {
        return cat.id === categoryId
      })
      if(category[0].fields && category[0].fields.Resources.length > 0) {
        let resourceOrdered = category[0].fields.Resources
        resourceOrdered.forEach(function(key) {
          var found = false;
          orderedResources = orderedResources.filter(function(res) {
            if(!found && res.id == key) {
              categoryResources.push(res);
              found = true;
              return false;
            } else {
              return true;
            }
          })
        })
        orderedResources = [...categoryResources]
      }
    }

    breadcrumbStore.addBreadcrumb({
      pageName: category,
      link: location.pathname,
      basePath: basePath,
      isParent: false,
      isActive: true
    })

    return (
      <Layout>
        <Fragment>
          <Hero heroTitle={category} heroBreadcrumb='Resources' />
          <div className='container__body full-width'>
            <div className='resource-category__container'>
              <ResourceHero category={params.category} />
              <ResourceCategoryList
                resources={orderedResources}
                category={category}
                showToast={this.showToast}
              />
            </div>
          </div>
        </Fragment>
      </Layout>
    )
  }
}

export default inject('resourceStore', 'breadcrumbStore')(observer(withRouter(ResourceCategory)))
