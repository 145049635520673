import React, { Component } from 'react'
import Button from '../elements/Button.js'
import FormCheckbox from '../elements/FormCheckbox.js'
import UserListRowFacilitators from '../molecules/UserListRowFacilitators.js'
import InviteStudentModal from '../molecules/InviteStudentModal.js'
import SendMessageModal from '../molecules/SendMessageModal.js'
import { observer, inject } from 'mobx-react'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import Loading from '../../ui/elements/Loading.js'
import { Box, Grid, Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc.js'

const GET_FACILITATORS_QUERY = gql`
  query getFacilitators {
    facilitators {
      id
      userId
      name
      lastName
      lastSeenAt
      bio
      bioNeedsApproval
      lastSeenAt
      createdAt
      cohorts {
        id
      }
    }
  }
`

class UserListFacilitators extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedReminder: false,
      checkedBoxes: {},
      modalShow: false,
      modalSendMessage: false,
      facilitators: []
    }
    this.checkAllCheckedBoxes = this.checkAllCheckedBoxes.bind(this)
    this.originalItems = props.facilitators
  }

  async componentDidMount() {
    const data = await this.props.client.query({
      query: GET_FACILITATORS_QUERY,
      fetchPolicy: 'network-only'
    })
    this.setState(
      {
        ...this.state,
        facilitators: data.data.facilitators
      },
      () => {
        this.setCheckedBoxes()
      }
    )
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  checkAllCheckedBoxes() {
    let oldState = Object.assign({}, this.state.checkedBoxes)
    let newState = {}
    let isSelected = !this.state.checkedReminder ? true : false
    Object.keys(oldState).forEach(key => {
      newState[key] = isSelected
    })
    this.setState({
      ...this.state,
      checkedBoxes: newState,
      checkedReminder: isSelected
    })
  }

  setCheckedBoxes = () => {
    const { facilitators } = this.state
    const checkedBoxes = {}
    facilitators.forEach(facilitator => (checkedBoxes[facilitator.id] = false))
    this.setState({
      ...this.state,
      checkedBoxes
    })
  }

  updatedCheckedBoxes = facilitatorId => {
    const newState = Object.assign({}, this.state.checkedBoxes)
    newState[facilitatorId] = newState[facilitatorId] ? false : true
    this.setState({
      ...this.state,
      checkedBoxes: newState,
      checkedReminder: Object.values(newState).includes(false) ? false : true
    })
  }

  render() {
    const { navigate } = this.props
    if (this.state.facilitators && this.state.facilitators.length > 0) {
      let modalClose = () => this.setState({ modalShow: false, modalSendMessage: false })
      const selectedTAs = this.state.facilitators
        .filter(ta => this.state.checkedBoxes[ta.id])
        .map(ta => ta.userId)
      return (
        <>
          <Box sx={{ pb: { xs: 4 } }}>
            <Box sx={{ px: { xs: 4, md: 0 } }}>
              <Grid container spacing={2}>
                <Grid item md={5} sm={12} xs={12}>
                  <Typography variant='h1' className='bold'>
                    Teaching Artists ({this.state.facilitators.length})
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={7}
                  sm={12}
                  xs={12}
                  sx={{
                    textAlign: {
                      xs: 'left',
                      sm: 'left',
                      md: 'right'
                    }
                  }}
                >
                  <Grid
                    container
                    spacing={{
                      xs: 3,
                      sm: 3,
                      md: 3
                    }}
                    columnSpacing={1}
                    alignItems='center'
                  >
                    <Grid item md={12} sm={12} xs={12}>
                      <Button
                        variant='contained'
                        title='Add a Teaching Artist'
                        onClick={() => navigate('/create-user/facilitator')}
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'fit-content'
                          }
                        }}
                      />
                      <Button
                        variant='outlined'
                        title='Message Selected People'
                        disabled={selectedTAs.length < 1}
                        onClick={() => this.setState({ modalSendMessage: true })}
                        sx={{
                          ml: 2,
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'fit-content'
                          },
                          display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'inline-flex'
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      sx={{
                        display: {
                          xs: 'block',
                          sm: 'block',
                          md: 'none'
                        }
                      }}
                    >
                      <Button
                        variant='outlined'
                        title='Message Selected People'
                        disabled={selectedTAs.length < 1}
                        onClick={() => this.setState({ modalSendMessage: true })}
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'fit-content'
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <>
              <SendMessageModal
                show={this.state.modalSendMessage}
                onHide={modalClose}
                userIds={selectedTAs}
              />
              <InviteStudentModal
                show={this.state.modalShow}
                onHide={modalClose}
                classUuid={this.props.classUuid}
              />
            </>
            <Box
              sx={{
                mt: { xs: 5, md: 4, lg: 5 },
                mb: { xs: '1.125rem', md: 2 },
                px: { xs: 3, md: 4 }
              }}
            >
              <Grid container alignItems='center'>
                <Grid item md={1} sm={1} xs={1}>
                  <Box sx={{ textAlign: 'center' }}>
                    <FormCheckbox
                      checked={this.state.checkedReminder}
                      onChange={this.checkAllCheckedBoxes}
                      value='checkedReminder'
                      color='primary'
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  md={5}
                  sm={6}
                  xs={9}
                  sx={{
                    display: {
                      xs: 'none',
                      lg: 'block'
                    }
                  }}
                >
                  <Typography variant='h4' className='table_header'>
                    User Details
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  sm={5}
                  xs={0}
                  sx={{
                    display: {
                      xs: 'none',
                      lg: 'block'
                    }
                  }}
                >
                  <Typography variant='h4' className='table_header'>
                    Classes
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  sm={0}
                  xs={0}
                  sx={{
                    display: {
                      xs: 'none',
                      lg: 'block'
                    }
                  }}
                >
                  <Typography variant='h4' className='table_header'>
                    Activity
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={11}
                  sm={11}
                  xs={11}
                  sx={{
                    display: {
                      xs: 'block',
                      lg: 'none'
                    }
                  }}
                >
                  <Typography variant='h4' className='table_header'>
                    Select All Rows
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box>
              {this.state.facilitators.map(facilitator => (
                <UserListRowFacilitators
                  key={facilitator.id}
                  lastSeenAt={facilitator.lastSeenAt}
                  facilitator={facilitator}
                  checked={this.state.checkedBoxes[facilitator.id] || false}
                  onChecked={() => this.updatedCheckedBoxes(facilitator.id)}
                  userId={facilitator.userId}
                />
              ))}
            </Box>
          </Box>
        </>
      )
    } else {
      return <Loading />
    }
  }
}

export default inject('userFacilitatorsFilterStore')(
  observer(withRouter(withApollo(UserListFacilitators)))
)
