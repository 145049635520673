import React, { Component, Fragment, createRef } from 'react'
import '../../pages/signup.scss'
import DatePicker from '../elements/DatePicker.js'
import { Typography, Box, Stepper, Step, StepLabel, MobileStepper } from '@mui/material'
import FormField from '../elements/FormField.js'
import PasswordFormField from '../elements/PasswordFormField.js'
import Button from '../elements/Button.js'
import { Link } from 'react-router-dom'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import Cookies from 'universal-cookie'
import { observer, inject } from 'mobx-react'
import libphonenumber from 'google-libphonenumber'
import moment from 'moment'
import DateUtils from '../../utils/DateUtils'
import FormSelect from '../elements/FormSelect'
import statesList from '../../utils/USAStates'
import { ChevronLeft, ChevronRight } from 'react-feather'
import { withApollo } from '@apollo/client/react/hoc'
import Validations from '../../utils/Validations'
import { validationText } from '../../content/validationText.js'
import { withRouter } from '../../utils/customHoc'
import HCaptcha from '@hcaptcha/react-hcaptcha';

const cookies = new Cookies()

const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $mobileNumber: String
    $address1: String
    $address2: String
    $city: String
    $state: String
    $dob: String
    $zipcode: String
    $role: String!
    $schoolId: Int
    $inviteUuid: String!
  ) {
    createUser(
      firstName: $firstName
      lastName: $lastName
      email: $email
      dob: $dob
      password: $password
      mobileNumber: $mobileNumber
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zipcode: $zipcode
      role: $role
      schoolId: $schoolId
      inviteUuid: $inviteUuid
    ) {
      accessToken
      client
      expiry
      id
      uid
      firstName
      lastName
      role
      profileId
    }
  }
`
const CheckUserEmailExist = gql`
  query CheckUserEmailExist($email: String!) {
    checkUserEmailExist(email: $email)
  }
`

class SignUpForm extends Component {
  constructor(props) {
    super(props)
    this.userStore = this.props.userStore
    this.breadcrumbStore = this.props.breadcrumbStore
    let { school } = props.userInviteData
    const hasSchool = school && school.mailingAddress
    this.state = {
      value: 'one',
      activeStep: 0,
      passwordError: false,
      firstName: props.userInviteData.firstName,
      lastName: props.userInviteData.lastName,
      email: props.userInviteData.email,
      dateOfBirth: null,
      password: '',
      passwordConfirmation: '',
      mobileNumber: null,
      address1: hasSchool ? school.mailingAddress.address1 : null,
      address2: hasSchool ? school.mailingAddress.address2 : null,
      city: hasSchool ? school.mailingAddress.city : null,
      state: hasSchool ? school.mailingAddress.state : null,
      zipcode: hasSchool ? school.mailingAddress.postCode : null,
      errors: [],
      token: null,
      isSubmitted: true
    }
    this.handleFormChange = this.handleFormChange.bind(this)
    this.validatePassword = this.validatePassword.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleErrors = this.handleErrors.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
    this.hcaptcha = React.createRef();
  }

  switchToStep(value) {
    const age = moment().diff(moment(this.state.dateOfBirth), 'years')
    const { mobileNumber } = this.state
    const role = this.props.userInviteData.role
    const oneFormFilled = this.state.email && this.state.firstName && this.state.lastName
    const twoFormFilled = this.state.password && this.state.passwordConfirmation
    const threeFormFilled = mobileNumber || role === 'student'
    const fourFormFilled =
      (this.state.address1 && this.state.city && this.state.state && this.state.zipcode) ||
      (!this.state.dateOfBirth || age > 12)
    const studentFourFormFilled = this.state.dateOfBirth

    switch (value) {
      case 'two':
        if (oneFormFilled) {
          this.setState({ value })
        } else {
          return null
        }
        return false
      case 'three':
        if (oneFormFilled && twoFormFilled) {
          this.shouldValidate(value)
        } else {
          return null
        }
        return false
      case 'four':
        if (oneFormFilled && twoFormFilled && threeFormFilled) {
          this.shouldValidate(value)
        } else {
          return null
        }
        return false
      case 'five':
        if (oneFormFilled && twoFormFilled && threeFormFilled) {
          if (role === 'student' && studentFourFormFilled) {
            this.shouldValidate(value)
          } else if (fourFormFilled) {
            this.shouldValidate(value)
          }
        }
        return false
      default:
        this.setState({ value })
        return null
    }
  }

  fetchEmailExist = async emailTextString => {
    const data = await this.props.client.query({
      query: CheckUserEmailExist,
      variables: {
        email: emailTextString
      },
      fetchPolicy: 'network-only'
    })
    return data
  }

  validateEmail = async emailTextString => {
    const validEmail = Validations.isValidEmail(emailTextString && emailTextString.toLowerCase())
    if (validEmail) {
      const checkEmailExist = await this.fetchEmailExist(emailTextString)
      this.setState({
        errors: {
          email: checkEmailExist.data.checkUserEmailExist
            ? validationText.user.error.emailExist
            : ''
        }
      })
    } else {
      this.setState({ errors: { email: validationText.user.error.email } })
    }
  }
  validateChange(e) {
    const fieldName = e.target.name
    const value = e.target.value

    switch (fieldName) {
      case 'firstName':
        if (value === '') {
          this.setState({
            errors: {
              ...this.state.errors,
              firstName: validationText.user.error.ownFirstName
            }
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, firstName: null }
          })
        }
        break

      case 'email':
        this.validateEmail(value)
        break
      case 'lastName':
        if (value === '') {
          this.setState({
            errors: {
              ...this.state.errors,
              lastName: validationText.user.error.ownLastName
            }
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, firstName: null }
          })
        }
        break
      case 'password':
        this.validatePassword(value, 'password')
        break
      case 'passwordConfirmation':
        this.validatePassword(value, 'passwordConfirmation')
        break
      case 'dateOfBirth':
        let age = moment().diff(value, 'years')
        if (isNaN(age)) {
          this.setState({
            errors: {
              ...this.state.errors,
              dateOfBirth: 'Please enter a valid date.'
            }
          })
        } else {
          if (age < 13) {
            this.setState({
              errors: {
                ...this.state.errors,
                dateOfBirth: validationText.user.error.dob
              }
            })
          } else {
            this.setState({
              errors: { ...this.state.errors, dateOfBirth: null }
            })
          }
        }

        break
      case 'mobileNumber':
        let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()
        try {
          let number = phoneUtil.parse(value, 'US')
          if (value.length === 10 && phoneUtil.isValidNumber(number)) {
            this.setState({
              errors: {
                ...this.state.errors,
                mobileNumber: null
              }
            })
          } else {
            this.setState({
              errors: {
                ...this.state.errors,
                mobileNumber: validationText.user.error.validMobileNumber
              }
            })
          }
        } catch (error) {
          this.setState({
            errors: {
              ...this.state.errors,
              mobileNumber: validationText.user.error.validMobileNumber
            }
          })
        }
        break
      case 'address1':
        if (value === '') {
          this.setState({
            errors: {
              ...this.state.errors,
              address1: validationText.user.error.address1
            }
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, address1: null }
          })
        }
        break
      case 'zipcode':
        if (value === '') {
          this.setState({
            errors: {
              ...this.state.errors,
              postCode: validationText.user.error.postCode
            }
          })
        } else if (!/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)) {
          this.setState({
            errors: {
              ...this.state.errors,
              postCode: validationText.user.error.validPostCode
            }
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, postCode: null }
          })
        }
        break
      case 'city':
        if (value === '') {
          this.setState({
            errors: {
              ...this.state.errors,
              city: validationText.user.error.city
            }
          })
        } else {
          this.setState({
            errors: { ...this.state.errors, city: null }
          })
        }
        break

      default:
        return
    }
  }
  handleFormChange(e) {
    this.setState({ [e.target.name]: e.target.value ? e.target.value : e.target.checked })
    this.validateChange(e)
  }

  shouldValidate(value) {
    if (this.state.activeStep === 1) {
      this.validatePassword(value)
    } else {
      this.setState({ value })
    }
  }

  validatePassword(value, element = 'password') {
    const { password, passwordConfirmation } = this.state

    const passwordLengthError = 'Passwords need to be at least 8 characters long.'
    const passwordMismatchError = 'Passwords do not match.'
    const length = element === 'password' ? password.length : passwordConfirmation.length

    this.setState({ [`${element}Error`]: '' })
    if (password && length < 8 && element === 'password') {
      this.setState({ [`${element}Error`]: passwordLengthError })
    } else if (password !== passwordConfirmation && passwordConfirmation && length < 8) {
      this.setState({
        passwordConfirmationError: passwordLengthError + ' ' + passwordMismatchError
      })
    } else if (password !== passwordConfirmation && passwordConfirmation && length >= 8) {
      this.setState({ passwordConfirmationError: passwordMismatchError })
    } else if (password === passwordConfirmation && length < 8) {
      this.setState({ [`${element}Error`]: passwordLengthError })
    } else if (password === passwordConfirmation && length >= 8) {
      this.setState({ activeStep: 1, passwordError: '', passwordConfirmationError: '' })
    }
  }

  handleSubmit(e, onMutate) {
    const { userInviteData } = this.props
    const { role, school } = userInviteData
    e.preventDefault()
    this.setState({ submitted: true })
    if (!this.state.submitted) {
      onMutate({
        variables: {
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          email: this.state.email,
          password: this.state.password,
          mobileNumber: this.state.mobileNumber || '',
          address1: this.state.address1,
          address2: this.state.address2,
          city: this.state.city,
          state: this.state.state,
          zipcode: String(this.state.zipcode),
          role: role,
          dob: DateUtils.formatDateString(this.state.dateOfBirth),
          schoolId: school ? parseInt(school.id) : null,
          inviteUuid: this.props.userInviteData.uuid
        }
      })
    }
  }

  handleErrors(error) {
    if (this.state.submitted) {
      this.setState({ submitted: false })
      if (error && error.message && this.state.value === 'five') {
        const message = error.message
          .split(' ')
          .slice(2)
          .join(' ')
        if (message === 'Invalid Mobile Number') {
          this.setState({ errors: { ...this.state.errors, mobileNumber: message }, value: 'three' })
        } else if (message === 'Invalid Birth Date') {
          this.setState({ errors: { ...this.state.errors, dateOfBirth: message }, value: 'one' })
        } else {
          this.setState({ errors: { ...this.state.errors, email: message }, value: 'one' })
        }
      }
    }
  }

  handleLogin(cache) {
    this.setState({ errors: [] })
    const loginPayloadKey = Object.keys(cache.optimisticData.data).filter(function(str) {
      return str.includes('CreateUserPayload')
    })[0]
    const normalData = cache.optimisticData.data[loginPayloadKey]
    const {
      firstName,
      lastName,
      accessToken,
      client,
      expiry,
      uid,
      role,
      profileId,
      mobileNumber,
      email
    } = normalData
    this.userStore.setFirstName(firstName)
    this.userStore.setLastName(lastName)
    this.userStore.setRole(role)
    this.userStore.setProfileId(profileId)
    this.userStore.setEmail(email || this.state.data || '')
    this.userStore.setIsAuthenticated(true)
    this.userStore.setPhoneNumber(mobileNumber)
    this.breadcrumbStore.setCurrentUserRole(role)
    cookies.set('WA_ACCESS_TOKEN', accessToken, { path: '/' })
    cookies.set('WA_CLIENT_TOKEN', client, { path: '/' })
    cookies.set('WA_EXPIRY', expiry, { path: '/' })
    cookies.set('WA_UID', uid, { path: '/' })
  }

  setActiveStep = stepKey => {
    const checkVaidate = this.validateStepperForms(stepKey)
    if (checkVaidate) {
      this.setState({ activeStep: stepKey })
    } else {
      return null
    }
  }

  validateStepperForms = stepKey => {
    const age = moment().diff(moment(this.state.dateOfBirth), 'years')
    const { mobileNumber } = this.state
    const role = this.props.userInviteData.role
    const oneFormFilled = this.state.email && this.state.firstName && this.state.lastName
    const twoFormFilled = this.state.password && this.state.passwordConfirmation
    const threeFormFilled = mobileNumber || role === 'student'
    const fourFormFilled =
      (this.state.address1 && this.state.city && this.state.state && this.state.zipcode) ||
      (!this.state.dateOfBirth || age > 12)
    const studentFourFormFilled = this.state.dateOfBirth

    switch (stepKey) {
      case 1:
        if (oneFormFilled) {
          return true
        }
        return false
      case 2:
        if (oneFormFilled && twoFormFilled) {
          this.shouldValidate(stepKey)
          return true
        }
        return false
      case 3:
        if (oneFormFilled && twoFormFilled && threeFormFilled) {
          this.shouldValidate(stepKey)
          return true
        }
        return false
      case 4:
        if (oneFormFilled && twoFormFilled && threeFormFilled) {
          if (role === 'student' && studentFourFormFilled) {
            this.shouldValidate(stepKey)
            return true
          } else if (fourFormFilled) {
            this.shouldValidate(stepKey)
            return true
          }
        }
        return false
      default:
        return true
    }
  }

  handleMobileStepper = opType => {
    let newActiveStep = opType === 'next' ? this.state.activeStep + 1 : this.state.activeStep - 1
    const checkVaidate = this.validateStepperForms(newActiveStep)
    if (checkVaidate) {
      this.setState({ activeStep: newActiveStep })
    } else {
      return null
    }
  }

  getStep0RoleMessage = () => {
    const { userInviteData } = this.props
    const { role } = userInviteData

    switch (role) {
      case 'student':
        return 'Please tell us your name and email address.'
      case 'teacher':
        return 'Your email will be shared with visiting teaching artists, wrkxfmly administrators, and curators. Your name will also be visible to students in your class.'
      case 'facilitator':
        return 'Your email will be shared with class teachers, wrkxfmly administrators, and curators. Your name will also be visible to students in your class.'
      case 'curator':
        return 'Your name and email will be shared with wrkxfmly administrators and other curators.'
      case 'admin':
        return 'Your name and email will be shared with wrkxfmly administrators.'
      default:
        return null
    }
  }

  getRole2Message = () => {
    const { userInviteData } = this.props
    const { role } = userInviteData

    switch (role) {
      case 'student':
        return (
          <>
            This will be used to send you automatic text message updates about your assignment. If
            you prefer not to enter your mobile number, you can still receive updates by email.
          </>
        )
      case 'teacher':
        return (
          <>
            Your mobile number will be shared with visiting teaching artists and wrkxfmly
            administrators.
          </>
        )
      case 'facilitator':
        return (
          <>Your mobile number will be shared with class teachers and wrkxfmly administrators.</>
        )
      case 'curator':
        return (
          <>Your mobile number will be shared with wrkxfmly administrators and other curators.</>
        )
      case 'admin':
        return <>This will be shared with wrkxfmly administrators.</>
      default:
        return null
    }
  }

  cancleSignUp = () => {
    return (
      <Link to={`/sign-up/${this.props.userInviteData.uuid}`}>
        <Typography variant='h4' className='link small_size strong'>
          {' '}
          Cancel
        </Typography>
      </Link>
    )
  }

  nextButton = (activeStep, maxSteps) => {
    const { userInviteData } = this.props
    const { role } = userInviteData

    let stepDisable = true
    switch (activeStep) {
      case 0: {
        stepDisable = !(
          this.state.firstName &&
          this.state.lastName &&
          this.state.email &&
          !this.state.errors.email
        )
        break
      }
      case 1: {
        stepDisable =
          !this.state.password ||
          !this.state.passwordConfirmation ||
          this.state.passwordError ||
          this.state.passwordConfirmationError ||
          this.state.password !== this.state.passwordConfirmation
        break
      }
      case 2:
        if (role === 'student') {
          stepDisable = !this.state.mobileNumber && !this.state.errors.mobileNumber ? false : true
        } else {
          stepDisable = !this.state.mobileNumber || this.state.errors.mobileNumber !== null
        }
        break
      case 3: {
        if (role === 'student') {
          stepDisable =
            this.state.dateOfBirth !== 'Invalid Date' && !this.state.errors.dateOfBirth
              ? false
              : true
        } else {
          stepDisable =
            this.state.errors.address1 ||
            this.state.errors.city ||
            this.state.errors.state ||
            this.state.errors.zipCode
        }
        break
      }
      default: {
        stepDisable = true
      }
    }
    const disabled = activeStep === maxSteps - 1 || activeStep === 'declined' || stepDisable
    const variant = disabled ? 'disabled_link' : 'link'
    const clickFn = disabled ? null : () => this.handleMobileStepper('next')
    return (
      <Typography
        variant={variant}
        onClick={clickFn}
        disabled={disabled}
        sx={{
          cursor: disabled ? '' : 'pointer',
          mr: { sm: 0.5 },
          textDecoration: 'none',
          svg: { verticalAlign: 'middle' }
        }}
        className='regular'
      >
        Next <ChevronRight size={24} />
      </Typography>
    )
  }

  backButton = activeStep => {
    const disabled = activeStep === 0 || activeStep === 'declined'
    const variant = disabled ? 'disabled_link' : 'link'
    const clickFn = disabled ? null : () => this.handleMobileStepper('back')
    return (
      <Typography
        variant={variant}
        onClick={clickFn}
        disabled={disabled}
        sx={{
          cursor: disabled ? '' : 'pointer',
          ml: { sm: 0.5 },
          textDecoration: 'none',
          svg: { verticalAlign: 'middle' }
        }}
        className='regular'
      >
        <ChevronLeft size={24} />
        Back
      </Typography>
    )
  }

  handleVerification = token => {
    this.setState({isSubmitted: token ? false : true})
  }

  render() {
    const { userInviteData } = this.props
    const { role } = userInviteData
    const {
      value,
      activeStep,
      mobileNumber,
      address1,
      address2,
      city,
      state,
      zipcode,
      passwordError,
      passwordConfirmationError
    } = this.state
    const { handleFormChange, handleSubmit, handleErrors, handleLogin } = this
    const forthStepText = role === 'student' ? 'Date of Birth' : 'Address'
    const formSteps = ['Name and Email', 'Password', 'Mobile', forthStepText, 'Terms']
    const maxSteps = formSteps.length
    const HCAPTCHA_SITE_KEY = process.env.REACT_APP_HCAPTCHA_SITE_KEY
    return (
      <Mutation
        mutation={CREATE_USER_MUTATION}
        update={(cache, { data: { signUp } }) => {
          handleLogin(cache)
        }}
        onCompleted={() => (window.location.pathname = '/')}
      >
        {(signUp, { data, error }) => {
          if (error) {
            handleErrors(error)
          }
          return (
            <Box
              className='sign-up-flow__container'
              sx={{
                px: { xs: 1.5 }
              }}
            >
              {value === 'declined' ? null : (
                <Box>
                  <Box
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block'
                      },
                      width: '100%',
                      pt: { md: 5 },
                      pb: { md: 4 }
                    }}
                  >
                    <Stepper activeStep={this.state.activeStep} alternativeLabel>
                      {formSteps.map((label, index) => (
                        <Step
                          key={label}
                          onClick={() => {
                            this.setActiveStep(index)
                          }}
                        >
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                  <Box
                    sx={{
                      display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'none'
                      },
                      pt: { xs: 1, sm: 5 }
                    }}
                  >
                    <MobileStepper
                      variant='text'
                      steps={maxSteps}
                      position='static'
                      activeStep={activeStep}
                      nextButton={this.nextButton(activeStep, maxSteps)}
                      backButton={this.backButton(activeStep)}
                    />
                  </Box>
                </Box>
              )}

              {activeStep === 0 && (
                <Box sx={{ pb: { xs: 1 } }}>
                  <Box className='sign-up-flow__tab-form' sx={{ mt: { xs: 0 }, pb: { xs: 0 } }}>
                    <Typography variant='h1' component='h1' className='bold'>
                      {role === 'student'
                        ? `Let's get your account set up.`
                        : `Confirm your name and email.`}
                    </Typography>
                    <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2, md: 3 } }}>
                      {this.getStep0RoleMessage()}
                    </Typography>
                  </Box>
                  <Box className='sign-up-flow__tab-form' sx={{ mt: { xs: 0 } }}>
                    <form>
                      <FormField
                        formFieldVariant='filled'
                        formFieldId='custom-css-outlined-first-name'
                        formFieldLabel='First Name'
                        formFieldType='text'
                        formFieldName='firstName'
                        formFieldDefaultValue={this.state.firstName}
                        onChange={handleFormChange}
                        formFieldError={this.state.errors.firstName}
                      />
                      <FormField
                        formFieldVariant='filled'
                        formFieldId='custom-css-outlined-last-name'
                        formFieldLabel='Last Name'
                        formFieldType='text'
                        formFieldName='lastName'
                        formFieldDefaultValue={this.state.lastName}
                        onChange={handleFormChange}
                        formFieldError={this.state.errors.lastName}
                        sx={{ mt: { xs: this.state.errors.firstName ? '10.5px' : 5 } }}
                      />
                      <FormField
                        formFieldVariant='filled'
                        formFieldId='custom-css-outlined-email'
                        formFieldLabel='Email'
                        formFieldType='email'
                        formFieldName='email'
                        formFieldDefaultValue={this.state.email}
                        onChange={handleFormChange}
                        formFieldError={this.state.errors.email}
                        sx={{ mt: { xs: this.state.errors.lastName ? '10.5px' : 5 } }}
                      />
                      <Button
                        variant='contained'
                        type='submit'
                        title='Continue'
                        disabled={
                          !(
                            this.state.firstName &&
                            this.state.lastName &&
                            this.state.email &&
                            !this.state.errors.email
                          )
                        }
                        onClick={() => {
                          this.setActiveStep(1)
                        }}
                        sx={{
                          width: { xs: '100%', sm: '50%', md: 'auto' },
                          mt: { xs: this.state.errors.email ? '10.5px' : 5 }
                        }}
                      />
                      <Box sx={{ mt: { xs: 2.7, md: 2 } }}>{this.cancleSignUp()}</Box>
                    </form>
                  </Box>
                </Box>
              )}

              {activeStep === 1 && (
                <Box sx={{ pb: { xs: 1 } }}>
                  <Box className='sign-up-flow__tab-form' sx={{ mt: { xs: 0 }, pb: { xs: 0 } }}>
                    <Typography variant='h1' component='h1' className='bold'>
                      Create a password.
                    </Typography>
                    <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2, md: 3 } }}>
                      Choose a password that you can easily remember and that is at least 8
                      characters long.
                    </Typography>
                  </Box>
                  <Box className='sign-up-flow__tab-form' sx={{ mt: { xs: 1 } }}>
                    <form>
                      <PasswordFormField
                        PasswordFormFieldValue={this.state.password}
                        passwordFormFieldId='outlined-adornment-enter-password'
                        passwordFormFieldVariant='filled'
                        passwordFormFieldName='password'
                        passwordFormFieldLabel='Password'
                        onChange={event => {
                          this.setState({ password: event.target.value }, () =>
                            this.handleFormChange(event)
                          )
                        }}
                        formFieldError={passwordError || null}
                      />
                      <Box sx={{ mt: { xs: passwordError ? '10.5px' : 5 } }}>
                        <PasswordFormField
                          PasswordFormFieldValue={this.state.passwordConfirmation}
                          passwordFormFieldId='outlined-adornment-confirm-password'
                          passwordFormFieldVariant='filled'
                          passwordFormFieldName='passwordConfirmation'
                          passwordFormFieldLabel='Confirm Password'
                          onChange={event => {
                            this.setState(
                              {
                                passwordConfirmation: event.target.value || ''
                              },
                              () => this.handleFormChange(event)
                            )
                          }}
                          formFieldError={passwordConfirmationError || null} //
                        />
                      </Box>
                      <Button
                        variant='contained'
                        type='submit'
                        title='Continue'
                        disabled={
                          !this.state.password ||
                          !this.state.passwordConfirmation ||
                          this.state.passwordError ||
                          this.state.passwordConfirmationError ||
                          this.state.password !== this.state.passwordConfirmation
                        }
                        onClick={() => {
                          this.setActiveStep(2)
                        }}
                        sx={{
                          width: { xs: '100%', sm: '50%', md: 'auto' },
                          mt: { xs: passwordConfirmationError ? '10.5px' : 5 }
                        }}
                      />
                      <Box sx={{ mt: { xs: 2.7, md: 2 } }}>{this.cancleSignUp()}</Box>
                    </form>
                  </Box>
                </Box>
              )}

              {activeStep === 2 && (
                <Box sx={{ pb: { xs: 0.7 } }}>
                  <Box className='sign-up-flow__tab-form' sx={{ mt: { xs: 0 }, pb: { xs: 0 } }}>
                    <Typography variant='h1' component='h1' className='bold'>
                      Enter your mobile number.
                    </Typography>
                    <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2, md: 3 } }}>
                      {this.getRole2Message()}
                    </Typography>
                  </Box>
                  <Box className='sign-up-flow__tab-form'>
                    <form>
                      <FormField
                        formFieldDefaultValue={mobileNumber}
                        formFieldVariant='filled'
                        formFieldId='custom-css-outlined-mobile-number'
                        formFieldLabel='Mobile Number'
                        formFieldType='number'
                        formFieldAutoComplete='tel'
                        required={false}
                        formFieldName='mobileNumber'
                        formFieldError={this.state.errors.mobileNumber}
                        onChange={handleFormChange}
                        formInputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                      />
                      <Box
                        sx={{
                          mt: { xs: this.state.errors.mobileNumber ? '10.5px' : 5 },
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: { xs: 'column', sm: 'row' },
                          gap: { xs: '24px', sm: '16px' }
                        }}
                      >
                        <Button
                          variant='contained'
                          type='submit'
                          title='Continue'
                          disabled={
                            !this.state.mobileNumber || this.state.errors.mobileNumber !== null
                          }
                          onClick={() => {
                            this.setActiveStep(3)
                          }}
                          sx={{ width: { xs: '100%', sm: '50%', md: 'auto' } }}
                        />

                        {role === 'student' ? (
                          <Button
                            variant='outlined'
                            title='Skip this step'
                            disabled={false}
                            onClick={() => {
                              this.setState({
                                mobileNumber: '',
                                errors: { ...this.state.errors, mobileNumber: null }
                              })
                              this.setActiveStep(3)
                            }}
                            sx={{ width: { xs: '100%', sm: '50%', md: 'auto' } }}
                          />
                        ) : null}
                      </Box>

                      <Box sx={{ mt: { xs: 2.6, md: 1.7 } }}>{this.cancleSignUp()}</Box>
                    </form>
                  </Box>
                </Box>
              )}

              {activeStep === 3 && (
                <Box sx={{ pb: { xs: 1 } }}>
                  {role === 'student' ? (
                    <Fragment>
                      <Box className='sign-up-flow__tab-form' sx={{ mt: { xs: 0 }, pb: { xs: 0 } }}>
                        <Typography variant='h1' component='h1' className='bold'>
                          Enter your date of birth.
                        </Typography>
                        <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2, md: 3 } }}>
                          If you are under 18 (or if you prefer not to enter your date of birth),
                          you may be required to ask your guardian to sign a use agreement form when
                          you submit your project.
                        </Typography>
                      </Box>
                      <Box component='div' className='sign-up-flow__tab-form'>
                        <form>
                          <DatePicker
                            onChange={date =>
                              handleFormChange({
                                target: {
                                  value: date,
                                  name: 'dateOfBirth'
                                }
                              })
                            }
                            value={this.state.dateOfBirth ? this.state.dateOfBirth : null}
                            helperText={this.state.errors.dateOfBirth}
                            error={this.state.errors.dateOfBirth}
                            label='Date of Birth'
                            sx={{ width: { lg: '100%' } }}
                          />
                          <Box
                            sx={{
                              mt: { xs: this.state.errors.dateOfBirth ? '17.1px' : 5 },
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: { xs: 'column', sm: 'row' },
                              gap: { xs: '24px', sm: '16px' }
                            }}
                          >
                            <Button
                              variant='contained'
                              type='submit'
                              title='Continue'
                              disabled={this.state.errors.dateOfBirth !== null}
                              onClick={() => {
                                this.setActiveStep(4)
                              }}
                              sx={{ width: { xs: '100%', sm: '50%', md: 'auto' } }}
                            />

                            {role === 'student' ? (
                              <Button
                                variant='outlined'
                                title='Skip this step'
                                disabled={false}
                                onClick={() => {
                                  this.setState({
                                    dateOfBirth: null,
                                    errors: { ...this.state.errors, dateOfBirth: null }
                                  })
                                  this.setActiveStep(4)
                                }}
                                sx={{ width: { xs: '100%', sm: '50%', md: 'auto' } }}
                              />
                            ) : null}
                          </Box>
                          <Box sx={{ mt: { xs: 2.6, md: 1.7 } }}>{this.cancleSignUp()}</Box>
                        </form>
                      </Box>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Box className='sign-up-flow__tab-form' sx={{ mt: { xs: 0 }, pb: { xs: 0 } }}>
                        <Typography variant='h1' component='h1' className='bold'>
                          Enter your preferred mailing address.
                        </Typography>

                        <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2, md: 3 } }}>
                          {role === 'teacher'
                            ? `We will send curriculum materials to your school address by
                            default. If you would prefer these to be sent to a different
                            address, please enter it here.`
                            : <>This will be shared with <i>wrkxfmly</i> administrators.</>}
                        </Typography>
                      </Box>
                      <div className='sign-up-flow__tab-form'>
                        <form>
                          <FormField
                            formFieldDefaultValue={address1 || ''}
                            formFieldVariant='filled'
                            formFieldId='custom-css-outlined-address-1'
                            formFieldLabel='Address Line 1'
                            formFieldType='text'
                            formFieldName='address1'
                            onChange={handleFormChange}
                            formFieldError={this.state.errors.address1}
                          />

                          <FormField
                            formFieldDefaultValue={address2 || ''}
                            formFieldVariant='filled'
                            formFieldId='custom-css-outlined-address-2'
                            formFieldLabel='Address Line 2 (optional)'
                            formFieldType='text'
                            formFieldName='address2'
                            onChange={handleFormChange}
                            formFieldError={this.state.errors.address2}
                            sx={{ mt: { xs: this.state.errors.address1 ? '10.5px' : 5 } }}
                          />

                          <FormField
                            formFieldDefaultValue={city || ''}
                            formFieldVariant='filled'
                            formFieldId='custom-css-outlined-city'
                            formFieldLabel='City'
                            formFieldType='text'
                            formFieldName='city'
                            onChange={handleFormChange}
                            formFieldError={this.state.errors.city}
                            sx={{ mt: { xs: 5 } }}
                          />
                          <Box
                            sx={{
                              mt: { xs: this.state.errors.city ? '10.5px' : 5 },
                              display: 'flex',
                              flexDirection: { xs: 'column', sm: 'row' }
                            }}
                          >
                            <Box sx={{ width: { sm: '48%', md: '99%' } }}>
                              <FormSelect
                                variant='filled'
                                inputProps={{ 'aria-label': 'Without label' }}
                                fullWidth={true}
                                defaultValue={state ? state : null}
                                value={state ? state : null}
                                options={statesList}
                                label='State'
                                placeholder='State'
                                onChange={e => this.setState({ state: e.target.value })}
                                name='curatorName'
                              />
                            </Box>
                            <Box
                              sx={{
                                width: { sm: '48%', md: '99%' },
                                mt: { xs: 3.5, sm: 0 },
                                ml: { sm: 2 }
                              }}
                            >
                              <FormField
                                formFieldDefaultValue={zipcode}
                                formFieldVariant='filled'
                                formFieldId='custom-css-outlined-zip'
                                formFieldLabel='Zip Code'
                                formFieldType='number'
                                formFieldName='zipcode'
                                formFieldError={this.state.errors.postCode}
                                onChange={handleFormChange}
                              />
                            </Box>
                          </Box>

                          <Button
                            variant='contained'
                            type='submit'
                            title='Continue'
                            disabled={
                              this.state.errors.address1 ||
                              this.state.errors.city ||
                              this.state.errors.state ||
                              this.state.errors.postCode
                            }
                            onClick={() => {
                              this.setActiveStep(4)
                            }}
                            sx={{
                              mt: { xs: this.state.errors.postCode ? '10.5px' : 5 },
                              width: { xs: '100%', sm: '50%', md: 'auto' }
                            }}
                          />

                          <Box sx={{ mt: { xs: 2.7, md: 1.7 } }}>{this.cancleSignUp()}</Box>
                        </form>
                      </div>
                    </Fragment>
                  )}
                </Box>
              )}

              {activeStep === 4 && (
                <Box sx={{ pb: { xs: 1 } }}>
                  <Box className='sign-up-flow__tab-form'>
                    <Typography variant='h1' component='h1' className='bold'>
                      Read and accept the application terms of use.
                    </Typography>

                    <Box sx={{ textAlign: 'left' }}>
                      <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2, md: 3 } }}>
                        Working Assumptions takes your privacy seriously. We commit to keeping your
                        information and the work that you share with us secure.
                      </Typography>
                      <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2 } }}>
                        To finish creating your account, please confirm that you have read and
                        accept our{' '}
                        <Link to='/terms-of-use' target='_blank' rel='noopener noreferrer'>
                          <Typography component={'span'} variant='link' className='large'>
                            {' '}
                            Application Terms of Use
                          </Typography>
                        </Link>{' '}
                        and{' '}
                        <Link to='/privacy-policy' target='_blank' rel='noopener noreferrer'>
                          <Typography component={'span'} variant='link' className='large'>
                            {' '}
                            Application Privacy Policy
                          </Typography>
                        </Link>
                        .
                        {role === 'student'
                          ? ' If you are under 18, review these pages with your parent or guardian before you create an account.'
                          : null}
                      </Typography>
                      {role === 'student' && (
                        <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2 } }}>
                          You can also read our{' '}
                          <Link
                            to={this.props.resourceStore.getResourceUrlByName('Family Guide')}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <Typography component={'span'} variant='link' className='large'>
                              Family Guide
                            </Typography>
                          </Link>{' '}
                          to learn about how the work that you upload to your account may be
                          included in the Working Assumptions digital research archive.
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <form>
                    <Box className='sign-up-flow__tab-form'>
                      <HCaptcha
                        ref={this.hcaptcha}
                        sitekey= {HCAPTCHA_SITE_KEY}
                        onVerify={this.handleVerification}
                      />
                    </Box>
                    <Box
                      className='sign-up-flow__tab-form'
                      sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        mt: { xs: 1 },
                        width: { md: 'fit-content' },
                        pt: 0
                      }}
                    >
                      <Box>
                        <Button
                          variant='contained'
                          type='submit'
                          disabled={this.state.isSubmitted}
                          title='Accept and Create Account'
                          onClick={e => handleSubmit(e, signUp)}
                          sx={{ width: { xs: '100%', md: 'auto' } }}
                        />
                      </Box>
                      <Box sx={{ mt: { xs: 3, md: 0 }, ml: { md: 2 } }}>
                        <Button
                          variant='outlined'
                          type='submit'
                          title='Decline'
                          onClick={() => {
                            this.setState({ activeStep: 'declined' })
                          }}
                          sx={{ width: { xs: '100%', md: 'auto' } }}
                        />
                      </Box>
                    </Box>
                  </form>
                </Box>
              )}

              {activeStep === 'declined' && (
                <Box
                  className='sign-up-flow__tab-content--declined'
                  sx={{ pb: { xs: 5 }, mt: { xs: 4 } }}
                >
                  <Typography variant='h1' component='h1' className='bold'>
                    You declined the application terms of use.
                  </Typography>

                  <Typography variant='paragraph' component='p' sx={{ mt: { xs: 2, md: 3 } }}>
                    We cannot create your account at this time. Your information has not been
                    stored.
                  </Typography>

                  {role === 'student' ? (
                    <Typography variant='paragraph' component='p'>
                      Please speak to your teacher and come back later if you wish to join the
                      assignment.
                    </Typography>
                  ) : (
                    <Typography variant='paragraph' component='p'>
                      Please contact{' '}
                      <a
                        href='mailto:education@workingassumptions.org'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        education@workingassumptions.org
                      </a>{' '}
                      if you wish to create an account.
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          )
        }}
      </Mutation>
    )
  }
}

export default inject('userStore', 'breadcrumbStore', 'resourceStore')(
  withApollo(withRouter(observer(SignUpForm)))
)
