import React from 'react'
import Dashboard from '../pages/Dashboard.jsx'
import MyAccount from '../pages/MyAccount.jsx'
import StyleGuide from '../pages/StyleGuide.jsx'
import ProjectDetailPage from '../pages/ProjectDetail.jsx'
import PhotoDetailPage from '../pages/PhotoDetailPage.jsx'
import Logout from '../pages/Logout.jsx'
import Classes from '../pages/Classes.jsx'
import JoinClass from '../pages/JoinClass.jsx'
import Resources from '../pages/Resources.jsx'
import Notifications from '../pages/Notifications.jsx'
import ResourceCategory from '../pages/ResourceCategory.jsx'
import ClassDetails from '../pages/ClassDetails.jsx'
import ClassReview from '../pages/ClassReview.jsx'
import Reviews from '../pages/Reviews.jsx'
import Schools from '../pages/Schools.jsx'
import CreateSchool from '../pages/CreateSchool.jsx'
import SchoolDetails from '../pages/SchoolDetails.jsx'
import UseAgreement from '../pages/UseAgreement.jsx'
import UserDetails from '../pages/UserDetails.jsx'
import ModelRelease from '../pages/ModelRelease.jsx'
import CreateClass from '../pages/CreateClass.jsx'
import CreateUser from '../pages/CreateUser.jsx'
import Users from '../pages/Users.jsx'
import Projects from '../pages/Projects.jsx'
import Events from '../pages/Events'
import CreateEvent from '../pages/CreateEvent'
import PhotoTags from '../pages/PhotoTags'

// PUBLIC SITE
import Homepage from '../pages/Homepage.jsx'
import Collection from '../pages/Collection.jsx'
import PublicEventDetails from '../pages/PublicEventDetails.jsx'
import PublicResources from '../pages/PublicResources.jsx'
import ForgotPassword from '../pages/ForgotPassword.jsx'
import ResetPassword from '../pages/ResetPassword.jsx'
import Login from '../pages/Login.jsx'
import SignUp from '../pages/SignUp.jsx'
import SignUpFlow from '../pages/SignUpFlow.jsx'

// TERMS PAGES
import WebTermsEnglish from '../pages/WebTermsEnglish.jsx'
import AppTermsEnglish from '../pages/AppTermsEnglish.jsx'
import AppTermsEspanol from '../pages/AppTermsEspanol.jsx'
import TermsOfUseEnglish from '../pages/TermsOfUseEnglish.jsx'
import AppPrivacyEnglish from '../pages/AppPrivacyEnglish.jsx'
import PrivacyPolicyEnglish from '../pages/PrivacyPolicyEnglish.jsx'
import AppPrivacyEspanol from '../pages/AppPrivacyEspanol.jsx'
import PhotographUseEnglish from '../pages/PhotographUseEnglish.jsx'
import PhotographUseEspanol from '../pages/PhotographUseEspanol.jsx'
import ExternalLinks from '../pages/ExternalLinks.jsx'
import AwardedPhotoPage from '../pages/AwardedPhotoPage.jsx'
import ErrorPage from '../pages/ErrorPage.jsx'
import UseAgreementDetail from '../pages/UseAgreementDetail.jsx'
import ModelReleaseDetail from '../pages/ModelReleaseDetail.jsx'

export const paths = {
  classReview: id => `/class-review/${id}`,
  classReviewStudents: id => `/class-review/${id}/students`,
  classReviewAwardProcessing: id => `/class-review/${id}/award-processing`,
  classReviewClassPhotographs: id => `/class-review/${id}/class-photographs`,
  classReviewClassDetails: id => `/class-review/${id}/class-details`,
  classArchiveTools: id => `/class-review/${id}/archive-tools`
}

export const routeAssociations = {
  dashboard: ['/dashboard'],
  classes: ['/classes', '/create-class', '/class-detail', '/class-tracker'],
  users: ['/users', '/create-user', '/user-details/'],
  projects: ['/projects', '/project-detail/', '/self-use-agreement/'],
  schools: ['/schools', '/school-details/'],
  reviews: ['/reviews', '/class-review/'],
  photos: ['/national-photos'],
  resources: ['/resources', '/resource-category/'],
  notifications: ['/notifications'],
  events: ['/events'],
  account: ['/account-details'],
  biography: ['/my-biography']
}

export const publicRoutes = [
  {
    path: '/join-class/:classUuid',
    component: ({ params }) => <JoinClass />,
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
  },
  {
    path: '/',
    component: () => <Homepage />,
    exact: true,
    roles: []
  },
  {
    path: '/collection',
    component: () => <Collection type='national' />,
    exact: true,
    roles: []
  },
  {
    path: '/covid-collection',
    component: () => <Collection type='covid' />,
    exact: true,
    roles: []
  },
  {
    path: '/public-event-details/:slug',
    component: ({ params }) => <PublicEventDetails slug={params.slug} />,
    exact: true,
    roles: []
  },
  {
    path: '/public-resources',
    component: () => <PublicResources />,
    exact: true,
    roles: []
  },
  {
    path: '/web-terms-english',
    component: () => <WebTermsEnglish />,
    exact: true,
    roles: []
  },
  {
    path: '/privacy-policy',
    component: () => <PrivacyPolicyEnglish />,
    exact: true,
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
  },
  {
    path: '/terms-of-use',
    component: () => <TermsOfUseEnglish />,
    exact: true,
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
  },
  {
    path: '/app-terms-english',
    component: () => <AppTermsEnglish />,
    exact: true,
    roles: []
  },
  {
    path: '/app-terms-espanol',
    component: () => <AppTermsEspanol />,
    exact: true,
    roles: []
  },
  {
    path: '/app-privacy-english',
    component: () => <AppPrivacyEnglish />,
    exact: true,
    roles: []
  },
  {
    path: '/app-privacy-espanol',
    component: () => <AppPrivacyEspanol />,
    exact: true,
    roles: []
  },
  {
    path: '/photograph-use-english',
    component: () => <PhotographUseEnglish />,
    exact: true,
    roles: []
  },
  {
    path: '/photograph-use-espanol',
    component: () => <PhotographUseEspanol />,
    exact: true,
    roles: []
  },
  {
    path: '/login',
    component: () => <Login />,
    exact: true,
    roles: []
  },
  {
    path: '/sign-up/:uuid',
    component: ({ params }) => <SignUp />,
    exact: true,
    roles: ['student']
  },
  {
    path: '/sign-up-flow/:uuid',
    component: ({ params }) => <SignUpFlow />,
    exact: true,
    roles: []
  },
  {
    path: '/forgot-password',
    component: () => <ForgotPassword />,
    exact: true,
    roles: []
  },
  {
    path: '/reset-password/:token',
    component: ({ params }) => <ResetPassword />,
    exact: true,
    roles: []
  },
  {
    path: '/model-release/:releaseUuid',
    component: ({ params }) => <ModelRelease />,
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
  },
  {
    path: '/use-agreement',
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin'],
    child: [
      {
        path: ':agreementUuid',
        component: ({ params }) => <UseAgreement />
      }
    ]
  },
  {
    path: '/use-agreement-detail/:agreementUuid',
    component: ({ params }) => <UseAgreementDetail />,
    exact: true,
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
  },
  {
    path: '/model-release-detail/:releaseUuid',
    component: ({ params }) => <ModelReleaseDetail />,
    exact: true,
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
  },
  {
    path: '/photo-detail/:photoId',
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin'], // :photoId
    component: ({ history, location, params }) => {
      return <PhotoDetailPage isModal={false} viewType='public' />
    }
  },
  {
    path: '/403',
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin'],
    component: () => <ErrorPage errorCode='403' />,
    exact: true
  },
  {
    path: '/404',
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin'],
    component: () => <ErrorPage errorCode='404' />,
    exact: true
  },
  {
    path: '*',
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin'],
    component: () => <ErrorPage errorCode='404' />
  }
]

export const unprotectedRoutes = [...publicRoutes]

export const routes = [
  {
    path: '/dashboard',
    exact: true,
    component: () => <Dashboard />,
    roles: ['student', 'appadmin']
  },
  // {
  //   path: '/photo-detail/:photoId', // :photoId
  //   component: ({ history, location, params }) => {
  //     return <PhotoDetailPage isModal={false} viewType='private' />
  //   },
  //   roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
  // },
  {
    path: '/projects',
    component: () => <Projects />,
    exact: true,
    roles: ['student', 'appadmin']
  },
  {
    path: '/project-detail',
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin'],
    child: [
      {
        path: ':projectId/:currentTab?',
        component: ({ params }) => <ProjectDetailPage />,
        exact: true,
        roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
      },
      {
        path: ':projectId/:photoId/:currentTab?',
        component: ({ params }) => (
          <ProjectDetailPage projectId={params.projectId} photoId={params.photoId} />
        ),
        exact: true,
        roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
      }
    ]
  },
  {
    path: '/create-class/',
    component: ({ params }) => <CreateClass />,
    exact: true,
    roles: ['teacher', 'appadmin']
  },
  {
    path: '/create-user/:role',
    component: ({ params }) => <CreateUser />,
    exact: true,
    roles: ['appadmin']
  },
  {
    path: '/class-detail',
    roles: ['facilitator', 'teacher', 'appadmin'],
    child: [
      {
        path: ':classId/:currentTab?/:scope?/:scopedProject?',
        component: ({ params }) => <ClassDetails />,
        exact: true,
        roles: ['facilitator', 'teacher', 'appadmin']
      }
    ]
  },
  {
    path: '/class-review',
    roles: ['appadmin', 'curator'],
    child: [
      {
        path: ':classId/:currentTab?/:classPhotoScope?/:photoId?',
        component: ({ params }) => <ClassReview />,
        exact: true,
        roles: ['facilitator', 'teacher', 'appadmin', 'curator']
      }
    ]
  },
  {
    path: '/classes/:currentTab/:page?',
    component: () => <Classes />,
    exact: true,
    roles: ['facilitator', 'teacher', 'appadmin']
  },
  {
    path: '/resources',
    component: () => <Resources />,
    roles: ['student', 'teacher', 'facilitator', 'appadmin', 'curator']
  },
  {
    path: '/photograph-use-english',
    component: () => <PhotographUseEnglish />,
    exact: true,
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
  },
  {
    path: '/resource-category/:categoryId/:category?',
    component: ({ params }) => <ResourceCategory />,
    exact: true,
    roles: ['student', 'teacher', 'facilitator', 'appadmin', 'curator']
  },
  {
    path: '/user-details',
    roles: ['appadmin'],
    child: [
      {
        path: ':userId/:currentTab?',
        component: ({ params }) => <UserDetails />,
        roles: ['appadmin']
      }
    ]
  },
  {
    path: '/users/:currentTab?',
    component: ({ params }) => <Users />,
    roles: ['appadmin']
  },
  {
    path: '/schools',
    component: () => <Schools />,
    roles: ['appadmin']
  },
  {
    path: '/create-school',
    component: () => <CreateSchool />,
    roles: ['appadmin']
  },
  {
    path: '/events',
    component: () => <Events />,
    exact: true,
    roles: ['appadmin']
  },
  {
    path: '/events/create-event',
    component: () => <CreateEvent />,
    roles: ['appadmin']
  },
  {
    path: '/notifications',
    component: () => <Notifications />,
    roles: ['appadmin']
  },
  {
    path: '/school-details',
    roles: ['appadmin', 'teacher', 'facilitator'],
    child: [
      {
        path: ':schoolId/:currentTab?',
        component: ({ params }) => <SchoolDetails />,
        roles: ['appadmin', 'teacher', 'facilitator']
      }
    ]
  },
  {
    path: '/reviews/:currentTab/:page?',
    component: () => <Reviews />,
    roles: ['curator', 'appadmin']
  },
  {
    path: '/national-photos',
    component: () => <Collection type='national' />,
    roles: ['student', 'teacher', 'facilitator', 'curator', 'appadmin']
  },
  {
    path: '/account-details/:currentTab?',
    component: () => <MyAccount />,
    exact: true,
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
  },
  {
    path: '/style-guide',
    component: () => <StyleGuide />,
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
  },
  {
    path: '/logout',
    component: () => <Logout />,
    roles: ['student', 'facilitator', 'curator', 'teacher', 'appadmin']
  },
  {
    path: '/photo-tags',
    component: () => <PhotoTags />,
    exact: true,
    roles: ['appadmin']
  },
  {
    path: '/external-links',
    component: () => <ExternalLinks />,
    exact: true,
    roles: ['appadmin']
  },
  {
    path: '/awarded-photos',
    roles: ['appadmin', 'teacher', 'facilitator'],
    child: [
      {
        path: ':award/:projectId',
        component: ({ params }) => <AwardedPhotoPage />,
        exact: true,
        roles: ['appadmin', 'teacher', 'facilitator']
      }
    ]
  },
  {
    path: '/self-use-agreement',
    roles: ['appadmin', 'student'],
    child: [
      {
        path: ':photoId',
        component: ({ params }) => <UseAgreement />,
        exact: true,
        roles: ['appadmin', 'student']
      }
    ]
  }
]
