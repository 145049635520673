import { useMutation } from '@apollo/client'
import { Box, Grid, Typography } from '@mui/material'
import _ from 'lodash'
import { inject } from 'mobx-react'
import { SEND_FORGOT_PASSWORD_MUTATION } from '../../../queries/userDetailsQueries.js'
import Button from '../../elements/Button'
import variables from '../../settings/_variables.scss'

const SecurityInfo = props => {
  const { user } = props
  const { profile } = user
  const role = profile.__typename

  const [sendPasswordResetMutation] = useMutation(SEND_FORGOT_PASSWORD_MUTATION, {
    variables: {
      id: user.id
    },
    onCompleted(data) {
      props.showToast(
        'success',
        `Password reset sent to ${user.firstName} ${user.lastName}'s email.`
      )
    }
  })

  const handleSendPasswordReset = async () => {
    try {
      await sendPasswordResetMutation()
    } catch (error) {
      console.error('Error sending password reset:', error)
    }
  }

  return props.userStore.role === 'appadmin' && props.user.id !== props.userStore.id ? (
    <Box
      sx={{
        borderTop: `1px solid ${variables.lightGrey}`,
        pb: { xs: 7, sm: 10, md: 10, lg: 10 },
        pt: { xs: 7, sm: 10, md: 10, lg: 10 }
      }}
    >
      <Box>
        <Typography variant='h2' component='h2'>
          Security
        </Typography>
      </Box>
      <Box sx={{ mt: 3, mb: { xs: 5, sm: 6, md: 6 } }}>
        <Grid container>
          <Grid item md={7} sm={12} xs={12}>
            <Typography variant='h3' className='semi-strong'>
              Password Reset
            </Typography>
          </Grid>
          <Grid item md={7} sm={12} xs={12}>
            <Box sx={{ mt: 1.5 }}>
              <Typography variant='paragraph' component='p'>
                The user will receive an email to their registered email address. Ask the user to
                click the link in the email and follow the instructions to reset their password.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ mt: 5 }}>
          <Button
            type='submit'
            title='Send Password Reset'
            disabled={false}
            onClick={handleSendPasswordReset}
            sx={{ width: { xs: '100%', sm: 'auto', md: 'auto' } }}
          />
        </Box>
      </Box>
      <Box>
        <Grid container>
          <Grid item md={7} sm={12} xs={12}>
            <Box>
              <Typography variant='h3' className='semi-strong'>
                Delete User
              </Typography>
            </Box>
          </Grid>
          <Grid item md={7} sm={12} xs={12}>
            <Box sx={{ mt: 1.5 }}>
              <Typography variant='paragraph' component='p'>
                {_.includes(['Student'], role) ? (
                  <>
                    Deleting the user will remove all of their photographs from the system. To
                    delete a user account, please contact the web app engineering team.
                  </>
                ) : _.includes(['Teacher', 'Facilitator', 'Curator'], role) ? (
                  <>To delete a user account, please contact the web app engineering team.</>
                ) : _.includes(['AppAdmin'], role) ? (
                  <>
                    To delete a user account, please contact the web app engineering team. You can
                    deactivate this admin user in the users list. The user will no longer be able to
                    log in, or receive emails from the web app.
                  </>
                ) : null}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  ) : null
}

export default inject('userStore')(SecurityInfo)
