import React, { Component } from 'react'
import Button from '../elements/Button.js'
import FormCheckbox from '../elements/FormCheckbox.js'
import UserListRowAdmins from '../molecules/UserListRowAdmins.js'
import InviteStudentModal from '../molecules/InviteStudentModal.js'
import SendMessageModal from '../molecules/SendMessageModal.js'
import { observer, inject } from 'mobx-react'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import Loading from '../../ui/elements/Loading.js'
import { Box, Grid, Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc.js'

const GET_ADMINS_QUERY = gql`
  query getAdmins {
    admins {
      id
      userId
      name
      lastName
      lastSeenAt
      name
      accountDisabledAt
    }
  }
`

class UserListAdmins extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedReminder: false,
      checkedBoxes: {},
      modalShow: false,
      modalSendMessage: false,
      admins: []
    }
    this.checkAllCheckedBoxes = this.checkAllCheckedBoxes.bind(this)
    this.fetchData = this.fetchData.bind(this)
    this.originalItems = props.admins
  }

  async componentDidMount() {
    await this.fetchData()
  }

  fetchData = async () => {
    const data = await this.props.client.query({
      query: GET_ADMINS_QUERY,
      fetchPolicy: 'network-only'
    })
    this.setState(
      {
        ...this.state,
        admins: data.data.admins
      },
      () => {
        this.setCheckedBoxes()
      }
    )
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  checkAllCheckedBoxes() {
    let oldState = Object.assign({}, this.state.checkedBoxes)
    let newState = {}
    let isSelected = !this.state.checkedReminder ? true : false
    Object.keys(oldState).forEach(key => {
      newState[key] = isSelected
    })
    this.setState({
      ...this.state,
      checkedBoxes: newState,
      checkedReminder: isSelected
    })
  }

  setCheckedBoxes = () => {
    const { admins } = this.state
    const checkedBoxes = {}
    admins.forEach(admin => (checkedBoxes[admin.id] = false))
    this.setState({
      ...this.state,
      checkedBoxes
    })
  }

  updatedCheckedBoxes = adminId => {
    const newState = Object.assign({}, this.state.checkedBoxes)
    newState[adminId] = newState[adminId] ? false : true
    this.setState({
      ...this.state,
      checkedBoxes: newState,
      checkedReminder: Object.values(newState).includes(false) ? false : true
    })
  }

  render() {
    const { navigate } = this.props
    if (this.state.admins && this.state.admins.length > 0) {
      let modalClose = () => this.setState({ modalShow: false, modalSendMessage: false })

      const selectedAdmins = this.state.admins
        .filter(admin => this.state.checkedBoxes[admin.id])
        .map(admin => admin.userId)
      return (
        <>
          <Box sx={{ pb: { xs: 4 } }}>
            <Box sx={{ px: { xs: 4, md: 0 } }}>
              <Grid container spacing={2}>
                <Grid item md={5} sm={12} xs={12}>
                  <Typography variant='h1' className='bold'>
                    Administrators ({this.state.admins.length})
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={7}
                  sm={12}
                  xs={12}
                  sx={{
                    textAlign: {
                      xs: 'left',
                      sm: 'left',
                      md: 'right'
                    }
                  }}
                >
                  <Grid
                    container
                    spacing={{
                      xs: 3,
                      sm: 3,
                      md: 3
                    }}
                    columnSpacing={1}
                    alignItems='center'
                  >
                    <Grid item md={12} sm={12} xs={12}>
                      <Button
                        variant='contained'
                        title='Add an Administrator'
                        onClick={() => navigate('/create-user/appadmin')}
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'fit-content'
                          }
                        }}
                      />
                      <Box sx={{ display: { xs: 'block', sm: 'none' }, height: 1 }} />
                      <Button
                        variant='outlined'
                        title='Message Selected People'
                        disabled={selectedAdmins.length < 1}
                        onClick={() => this.setState({ modalSendMessage: true })}
                        sx={{
                          ml: 2,
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'fit-content'
                          },
                          display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'inline-flex'
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      sx={{
                        display: {
                          xs: 'block',
                          sm: 'block',
                          md: 'none'
                        }
                      }}
                    >
                      <Button
                        variant='outlined'
                        title='Message Selected People'
                        disabled={selectedAdmins.length < 1}
                        onClick={() => this.setState({ modalSendMessage: true })}
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'fit-content'
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <>
              <SendMessageModal
                show={this.state.modalSendMessage}
                onHide={modalClose}
                userIds={selectedAdmins}
              />
              <InviteStudentModal
                show={this.state.modalShow}
                onHide={modalClose}
                classUuid={this.props.classUuid}
              />
            </>
            <Box
              sx={{
                mt: { xs: 5, md: 4, lg: 5 },
                mb: { xs: '1.125rem', md: 2 },
                px: { xs: 3, md: 4 }
              }}
            >
              <Grid container alignItems='center'>
                <Grid item md={1} sm={1} xs={1}>
                  <Box sx={{ textAlign: 'center' }}>
                    <FormCheckbox
                      checked={this.state.checkedReminder}
                      onChange={this.checkAllCheckedBoxes}
                      value='checkedReminder'
                      color='primary'
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  md={5}
                  sm={6}
                  xs={9}
                  sx={{
                    display: {
                      xs: 'none',
                      lg: 'block'
                    }
                  }}
                >
                  <Typography variant='h4' className='table_header'>
                    User Details
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={3}
                  sm={5}
                  xs={0}
                  sx={{
                    display: {
                      xs: 'none',
                      lg: 'block'
                    }
                  }}
                />
                <Grid
                  item
                  md={3}
                  sm={0}
                  xs={0}
                  sx={{
                    display: {
                      xs: 'none',
                      lg: 'block'
                    }
                  }}
                >
                  <Typography variant='h4' className='table_header'>
                    Activity
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={11}
                  sm={11}
                  xs={11}
                  sx={{
                    display: {
                      xs: 'block',
                      lg: 'none'
                    }
                  }}
                >
                  <Typography variant='h4' className='table_header'>
                    Select All Rows
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {this.state.admins.map(admin => (
              <UserListRowAdmins
                key={admin.id}
                lastSeenAt={admin.lastSeenAt}
                admin={admin}
                checked={this.state.checkedBoxes[admin.id] || false}
                onChecked={() => this.updatedCheckedBoxes(admin.id)}
                userId={admin.userId}
                showToast={this.props.showToast}
                refetchData={this.fetchData}
              />
            ))}
          </Box>
        </>
      )
    } else {
      return <Loading />
    }
  }
}

export default inject('userAdminsFilterStore')(observer(withRouter(withApollo(UserListAdmins))))
