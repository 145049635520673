import React, { Component, Fragment } from 'react'
import Button from '../elements/Button.js'
import FormCheckbox from '../elements/FormCheckbox.js'
import UserListRowStudents from '../molecules/UserListRowStudents.js'
import SendMessageModal from '../molecules/SendMessageModal.js'
import { observer, inject } from 'mobx-react'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import Loading from '../../ui/elements/Loading.js'
import ListSearchBar from '../elements/ListSearchBar.js'
import EmptyListDesign from '../molecules/EmptyListDesign.js'
import { getTextContentByPage } from '../../content/textContent'
import { Box, Grid, Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc.js'

const GET_STUDENTS_QUERY = gql`
  query PaginatedStudents($cursor: String, $search: String) {
    studentsConnection(first: 24, after: $cursor, search: $search) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          userId
          name
          lastName
          lastSeenAt
          createdAt
          totalAwardedPhotographs
          cohorts {
            id
            school {
              id
              name
            }
          }
          projects {
            id
          }
        }
      }
    }
    studentCount(search: $search)
  }
`
class UserListStudents extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedReminder: false,
      checkedBoxes: {},
      modalShow: false,
      modalSendMessage: false,
      studentCount: 0,
      searchKey: '',
      allCheckboxChecked: false
    }
    this.checkAllCheckedBoxes = this.checkAllCheckedBoxes.bind(this)
    this.originalItems = props.students
  }

  async componentWillMount() {
    this.timer = null
    const mockObj = {
      hasNextPage: true,
      endCursor: ''
    }
    await this.fetchMoreStudents(mockObj)
    this.setState(
      {
        ...this.state,
        loading: false
      },
      () => {
        this.setCheckedBoxes()
      }
    )

    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  makeStudentsArray = arr => {
    return arr.filter(elem => elem.node).map(elem => ({ ...elem.node }))
  }

  handleScroll = e => {
    const elem = e.target.scrollingElement
    const vh = elem.scrollHeight
    const currVh = elem.scrollTop
    const threshold = vh / 4 + 200
    if (currVh > threshold) {
      this.fetchMoreStudents(this.state?.gqlRes?.studentsConnection?.pageInfo)
    }
  }

  fetchMoreStudents = async ({ endCursor, hasNextPage }) => {
    if (!hasNextPage) return []
    this.setState({
      ...this.state,
      loading: true
    })
    const data = await this.props.client.query({
      query: GET_STUDENTS_QUERY,
      variables: {
        cursor: endCursor,
        search: this.state.searchKey
      },
      fetchPolicy: 'network-only'
    })
    return this.mergeIncomingData(data.data)
  }

  mergeIncomingData = data => {
    if (
      this.state.gqlRes &&
      this.state.gqlRes.studentsConnection.pageInfo.endCursor ===
        data.studentsConnection.pageInfo.endCursor
    )
      return
    let selectedCheckbox = this.state.checkedBoxes
    const current = (this.state.gqlRes && this.state.gqlRes.studentsConnection) || { edges: [] }
    const { edges } = current
    let newEdges = [...edges, ...data.studentsConnection.edges]
    let countStudent = data.studentCount
    let newCheckedboxes = {}
    let studentArr = this.makeStudentsArray(newEdges)
    let studentArrCheck = {}
    studentArr.forEach(student => {
      if (this.state.checkedReminder) {
        studentArrCheck[student.id] = true
        this.setState({
          ...this.state,
          checkedBoxes: studentArrCheck
        })
      }
      if (this.state.checkedBoxes[student.id])
        newCheckedboxes[student.id] = this.state.checkedBoxes[student.id]
      else newCheckedboxes[student.id] = this.state.checkedReminder
    })

    this.setState({
      ...this.state,
      gqlRes: {
        studentsConnection: {
          edges: newEdges,
          pageInfo: data.studentsConnection.pageInfo
        },
        studentCount: countStudent
      },
      loading: false,
      checkedBoxes: { ...selectedCheckbox, ...newCheckedboxes }
    })

    if (Object.values(this.state.checkedBoxes).includes(false)) {
      this.setState({ checkedReminder: false })
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  checkAllCheckedBoxes() {
    let oldState = Object.assign({}, this.state.checkedBoxes)
    let newState = {}
    let isSelected = !this.state.checkedReminder ? true : false
    Object.keys(oldState).forEach(key => {
      newState[key] = isSelected
    })
    this.setState({
      ...this.state,
      checkedBoxes: newState,
      checkedReminder: isSelected,
      allCheckboxChecked: true
    })
  }

  setCheckedBoxes = () => {
    const students = this.makeStudentsArray(this.state.gqlRes.studentsConnection.edges)
    const checkedBoxes = {}
    students.forEach(student => (checkedBoxes[student.id] = false))
    this.setState({
      ...this.state,
      checkedBoxes
    })
  }

  updatedCheckedBoxes = studentId => {
    const newState = Object.assign({}, this.state.checkedBoxes)
    newState[studentId] = newState[studentId] ? false : true
    this.setState({
      ...this.state,
      checkedBoxes: newState,
      checkedReminder: Object.values(newState).includes(false) ? false : true
    })
  }

  handelSearch = value => {
    clearTimeout(this.timer)
    this.setState(
      {
        ...this.state,
        searchKey: value
      },
      async () => {}
    )
    this.timer = setTimeout(this.triggerChange, 2000)
  }

  triggerChange = () => {
    this.setState(
      {
        ...this.state,
        loading: true,
        gqlRes: null
      },
      async () => {
        const mockObj = {
          hasNextPage: true,
          endCursor: ''
        }
        await this.fetchMoreStudents(mockObj)
      }
    )
  }

  render() {
    let modalClose = () => this.setState({ modalShow: false, modalSendMessage: false })
    let students =
      this.state.gqlRes && this.state.gqlRes.studentsConnection
        ? this.makeStudentsArray(this.state.gqlRes.studentsConnection.edges)
        : []
    if (this.state.gqlRes) {
      const selectedStudents = students
        .filter(student => this.state.checkedBoxes[student.id])
        .map(student => student.userId)
      return (
        <>
          <Box sx={{ pb: { xs: 4 } }}>
            <Box sx={{ px: { xs: 4, md: 0 } }}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant='h1' className='bold'>
                    Students ({this.state.gqlRes.studentCount})
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{
                    textAlign: {
                      xs: 'rignt',
                      sm: 'right',
                      md: 'right'
                    }
                  }}
                >
                  <Grid
                    container
                    spacing={{
                      xs: 3,
                      sm: 4,
                      md: 3
                    }}
                    alignItems='center'
                  >
                    <Grid
                      item
                      md={12}
                      sm={4}
                      xs={12}
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'none',
                          md: 'block'
                        }
                      }}
                    >
                      <Button
                        title='Message Selected People'
                        disabled={selectedStudents.length < 1}
                        onClick={() => this.setState({ modalSendMessage: true })}
                        sx={{
                          ml: 2,
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'fit-content'
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: 'block',
                          sm: 'block',
                          md: 'none'
                        }
                      }}
                    >
                      <Box sx={{ mt: { sm: -1 } }}>
                        <ListSearchBar
                          placeHolder='Search for a Student Name'
                          id='student-search'
                          onChange={e => this.handelSearch(e.target.value)}
                          value={this.state.searchKey}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      sx={{
                        display: {
                          xs: 'block',
                          sm: 'block',
                          md: 'none'
                        }
                      }}
                    >
                      <Button
                        title='Message Selected People'
                        disabled={selectedStudents.length < 1}
                        onClick={() => this.setState({ modalSendMessage: true })}
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'fit-content'
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'none',
                          md: 'block'
                        }
                      }}
                    >
                      <Box mt={{ xs: '-8px', sm: 'auto', md: '-6px' }}>
                        <ListSearchBar
                          placeHolder='Search for a Student Name'
                          id='student-search'
                          onChange={e => this.handelSearch(e.target.value)}
                          value={this.state.searchKey}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <SendMessageModal
                show={this.state.modalSendMessage}
                onHide={modalClose}
                userIds={selectedStudents}
              />
            </Box>

            {students.length > 0 ? (
              <Fragment>
                <Box
                  sx={{
                    mt: { xs: 5, md: 4, lg: 5 },
                    mb: { xs: '1.125rem', md: 2 },
                    px: { xs: 3, md: 4 }
                  }}
                >
                  <Grid container alignItems='center'>
                    <Grid item md={1} sm={1} xs={1}>
                      <Box sx={{ textAlign: 'center' }}>
                        <FormCheckbox
                          checked={this.state.checkedReminder}
                          onChange={this.checkAllCheckedBoxes}
                          value='checkedReminder'
                          color='primary'
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sm={6}
                      xs={9}
                      sx={{
                        display: {
                          xs: 'none',
                          lg: 'block'
                        }
                      }}
                    >
                      <Typography variant='dataLabel' component={'p'}>
                        User Details
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sm={5}
                      xs={0}
                      sx={{
                        display: {
                          xs: 'none',
                          lg: 'block'
                        }
                      }}
                    >
                      <Typography variant='dataLabel' component={'p'}>
                        Projects
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      sm={0}
                      xs={0}
                      sx={{
                        display: {
                          xs: 'none',
                          lg: 'block'
                        }
                      }}
                    >
                      <Typography variant='dataLabel' component={'p'}>
                        Activity
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      sx={{
                        display: {
                          xs: 'block',
                          lg: 'none'
                        },
                        ml: { xs: '3px' }
                      }}
                    >
                      <Typography variant='dataLabel' component={'p'}>
                        Select All Rows
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {students.map(student => (
                  <UserListRowStudents
                    key={student.id}
                    lastSeenAt={student.lastSeenAt}
                    student={student}
                    checked={this.state.checkedBoxes[student.id] || false}
                    onChecked={() => this.updatedCheckedBoxes(student.id)}
                    userId={student.userId}
                  />
                ))}
              </Fragment>
            ) : (
              <Box sx={{ mt: { xs: 4 }, px: { xs: 2, md: '0' } }}>
                <EmptyListDesign
                  heading={getTextContentByPage(
                    'USERLIST',
                    this.props.role.toUpperCase(),
                    'STUDENTLIST_EMPTYINTRO'
                  )}
                  subheading={getTextContentByPage(
                    'USERLIST',
                    this.props.role.toUpperCase(),
                    'STUDENTLIST_EMPTYSUBHEADING'
                  )}
                />
              </Box>
            )}
          </Box>
        </>
      )
    } else {
      return <Loading />
    }
  }
}

export default withRouter(inject('userStudentsFilterStore')(observer(withApollo(UserListStudents))))
