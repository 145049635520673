import React, { Component, Fragment } from 'react'
import Button from '../elements/Button.js'
import FormCheckbox from '../elements/FormCheckbox.js'
import UserListRowTeachers from '../molecules/UserListRowTeachers.js'
import InviteStudentModal from '../molecules/InviteStudentModal.js'
import SendMessageModal from '../molecules/SendMessageModal.js'
import { observer, inject } from 'mobx-react'
import { withApollo } from '@apollo/client/react/hoc'
import { gql } from '@apollo/client'
import Loading from '../../ui/elements/Loading.js'
import ListSearchBar from '../elements/ListSearchBar.js'
import EmptyListDesign from '../molecules/EmptyListDesign.js'
import { getTextContentByPage } from '../../content/textContent'
import { Box, Grid, Typography } from '@mui/material'
import { withRouter } from '../../utils/customHoc.js'

const GET_TEACHERS_QUERY = gql`
  query PaginatedTeachers($cursor: String, $search: String) {
    teachersConnection(first: 24, after: $cursor, search: $search) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          id
          userId
          name
          lastName
          lastSeenAt
          school {
            name
          }
          cohorts {
            id
          }
        }
      }
    }
    teacherCount(search: $search)
  }
`

class UserListTeachers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkedReminder: false,
      checkedBoxes: {},
      modalShow: false,
      modalSendMessage: false,
      gqlRes: null,
      teacherCount: 0,
      searchKey: ''
    }
    this.checkAllCheckedBoxes = this.checkAllCheckedBoxes.bind(this)
    this.originalItems = props.teachers
  }

  async componentDidMount() {
    const mockObj = {
      hasNextPage: true,
      endCursor: ''
    }
    await this.fetchMoreTeachers(mockObj)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  makeTeachersArray = arr => {
    return arr.filter(elem => elem.node).map(elem => ({ ...elem.node }))
  }

  handleScroll = e => {
    const elem = e.target.scrollingElement
    const vh = elem.scrollHeight
    const currVh = elem.scrollTop
    const threshold = vh / 4 + 200
    if (currVh > threshold) {
      this.fetchMoreTeachers(this.state.gqlRes.teachersConnection.pageInfo)
    }
  }

  fetchMoreTeachers = async ({ endCursor, hasNextPage }) => {
    if (!hasNextPage) return []
    this.setState({
      ...this.state,
      loading: true
    })
    const data = await this.props.client.query({
      query: GET_TEACHERS_QUERY,
      variables: {
        cursor: endCursor,
        search: this.state.searchKey
      },
      fetchPolicy: 'network-only'
    })
    return this.mergeIncomingData(data.data)
  }

  mergeIncomingData = data => {
    if (
      this.state.gqlRes &&
      this.state.gqlRes.teachersConnection.pageInfo.endCursor ===
        data.teachersConnection.pageInfo.endCursor
    )
      return
    const current = (this.state.gqlRes && this.state.gqlRes.teachersConnection) || { edges: [] }
    let countTeacher = data.teacherCount
    const { edges } = current
    let newEdges = [...edges, ...data.teachersConnection.edges]
    let newCheckedboxes = {}
    let teacherArr = this.makeTeachersArray(newEdges)
    let teacherArrCheck = {}
    teacherArr.forEach(teacher => {
      if (this.state.checkedReminder) {
        teacherArrCheck[teacher.id] = true
      }
      if (this.state.checkedBoxes[teacher.id])
        newCheckedboxes[teacher.id] = this.state.checkedBoxes[teacher.id]
      else newCheckedboxes[teacher.id] = false
    })

    this.setState({
      ...this.state,
      gqlRes: {
        teachersConnection: {
          edges: newEdges,
          pageInfo: data.teachersConnection.pageInfo
        },
        teacherCount: countTeacher
      },

      loading: false,
      checkedBoxes: { ...newCheckedboxes, ...teacherArrCheck }
    })
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked })
  }

  checkAllCheckedBoxes() {
    let oldState = Object.assign({}, this.state.checkedBoxes)
    let newState = {}
    let isSelected = !this.state.checkedReminder ? true : false
    Object.keys(oldState).forEach(key => {
      newState[key] = isSelected
    })
    this.setState({
      ...this.state,
      checkedBoxes: newState,
      checkedReminder: isSelected
    })
  }

  setCheckedBoxes = () => {
    const teachers = this.makeTeachersArray(this.state.gqlRes.teachersConnection.edges)
    const checkedBoxes = {}
    teachers.forEach(teacher => (checkedBoxes[teacher.id] = false))
    this.setState({
      ...this.state,
      checkedBoxes
    })
  }

  updatedCheckedBoxes = teacherId => {
    const newState = Object.assign({}, this.state.checkedBoxes)
    newState[teacherId] = newState[teacherId] ? false : true
    this.setState({
      ...this.state,
      checkedBoxes: newState,
      checkedReminder: Object.values(newState).includes(false) ? false : true
    })
  }

  handelSearch = value => {
    clearTimeout(this.timer)
    this.setState(
      {
        ...this.state,
        searchKey: value
      },
      async () => {}
    )
    this.timer = setTimeout(this.triggerChange, 2000)
  }

  triggerChange = () => {
    this.setState(
      {
        ...this.state,
        loading: true,
        gqlRes: null
      },
      async () => {
        const mockObj = {
          hasNextPage: true,
          endCursor: ''
        }
        await this.fetchMoreTeachers(mockObj)
      }
    )
  }

  render() {
    const { gqlRes } = this.state
    const { navigate } = this.props
    let teachers =
      gqlRes && gqlRes.teachersConnection
        ? this.makeTeachersArray(gqlRes.teachersConnection.edges)
        : []
    if (this.state.gqlRes) {
      const selectedTeachers = teachers
        .filter(teacher => this.state.checkedBoxes[teacher.id])
        .map(teacher => teacher.userId)
      let count = gqlRes && gqlRes.teacherCount ? gqlRes.teacherCount : teachers.length
      let modalClose = () => this.setState({ modalShow: false, modalSendMessage: false })
      return (
        <>
          <Box sx={{ pb: { xs: 4 } }}>
            <Box sx={{ px: { xs: 4, md: 0 } }}>
              <Grid container spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <Typography variant='h1' className='bold'>
                    Teachers ({count})
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  sx={{
                    textAlign: {
                      xs: 'rignt',
                      sm: 'right',
                      md: 'right'
                    }
                  }}
                >
                  <Grid
                    container
                    spacing={{
                      xs: 3,
                      sm: 4,
                      md: 3
                    }}
                    columnSpacing={1}
                    alignItems='center'
                  >
                    <Grid item md={12} sm={12} xs={12}>
                      <Button
                        variant='contained'
                        title='Add a Teacher'
                        onClick={() => navigate('/create-user/teacher')}
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'fit-content'
                          }
                        }}
                      />

                      <Button
                        title='Message Selected People'
                        disabled={selectedTeachers.length < 1}
                        onClick={() => this.setState({ modalSendMessage: true })}
                        sx={{
                          ml: 2,
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'fit-content'
                          },
                          display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'inline-flex'
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: 'block',
                          sm: 'block',
                          md: 'none'
                        }
                      }}
                    >
                      <Box>
                        <ListSearchBar
                          placeHolder='Search for a Teacher Name'
                          id='teacher-search'
                          onChange={e => this.handelSearch(e.target.value)}
                          value={this.state.searchKey}
                          sx={{ width: '100%' }}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      sx={{
                        display: {
                          xs: 'block',
                          sm: 'block',
                          md: 'none'
                        }
                      }}
                    >
                      <Button
                        title='Message Selected People'
                        disabled={selectedTeachers.length < 1}
                        onClick={() => this.setState({ modalSendMessage: true })}
                        sx={{
                          width: {
                            xs: '100%',
                            sm: '100%',
                            md: 'fit-content'
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      sm={12}
                      xs={12}
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'none',
                          md: 'block'
                        }
                      }}
                    >
                      <Box sx={{ mt: { md: '-6px' } }}>
                        <ListSearchBar
                          placeHolder='Search for a Teacher Name'
                          id='teacher-search'
                          onChange={e => this.handelSearch(e.target.value)}
                          value={this.state.searchKey}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <SendMessageModal
                show={this.state.modalSendMessage}
                onHide={modalClose}
                userIds={selectedTeachers}
              />
              <InviteStudentModal
                show={this.state.modalShow}
                onHide={modalClose}
                classUuid={this.props.classUuid}
              />
            </Box>
            {teachers.length > 0 ? (
              <Fragment>
                <Box
                  sx={{
                    mt: { xs: 5, md: 4, lg: 5 },
                    mb: { xs: '1.125rem', md: 2 },
                    px: { xs: 3, md: 4 }
                  }}
                >
                  <Grid container alignItems='center'>
                    <Grid item md={1} sm={1} xs={1}>
                      <Box sx={{ textAlign: 'center' }}>
                        <FormCheckbox
                          checked={this.state.checkedReminder}
                          onChange={this.checkAllCheckedBoxes}
                          value='checkedReminder'
                          color='primary'
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      md={5}
                      sm={6}
                      xs={9}
                      sx={{
                        display: {
                          xs: 'none',
                          lg: 'block'
                        }
                      }}
                    >
                      <Typography variant='dataLabel' component={'p'}>
                        User Details
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      sm={5}
                      xs={0}
                      sx={{
                        display: {
                          xs: 'none',
                          lg: 'block'
                        }
                      }}
                    >
                      <Typography variant='dataLabel' component={'p'}>
                        Classes
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={3}
                      sm={0}
                      xs={0}
                      sx={{
                        display: {
                          xs: 'none',
                          lg: 'block'
                        }
                      }}
                    >
                      <Typography variant='dataLabel' component={'p'}>
                        Activity
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      md={11}
                      sm={11}
                      xs={11}
                      sx={{
                        display: {
                          xs: 'block',
                          lg: 'none'
                        }
                      }}
                    >
                      <Typography variant='dataLabel' component={'p'}>
                        Select All Rows
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {teachers.map(teacher => (
                  <UserListRowTeachers
                    key={teacher.id}
                    lastSeenAt={teacher.lastSeenAt}
                    teacher={teacher}
                    checked={this.state.checkedBoxes[teacher.id] || false}
                    onChecked={() => this.updatedCheckedBoxes(teacher.id)}
                    userId={teacher.userId}
                  />
                ))}
              </Fragment>
            ) : (
              <Box sx={{ mt: { xs: 4 } }}>
                <EmptyListDesign
                  heading={getTextContentByPage(
                    'USERLIST',
                    this.props.role.toUpperCase(),
                    'TEACHERLIST_EMPTYINTRO'
                  )}
                  subheading={getTextContentByPage(
                    'USERLIST',
                    this.props.role.toUpperCase(),
                    'TEACHERLIST_EMPTYSUBHEADING'
                  )}
                />
              </Box>
            )}
          </Box>
        </>
      )
    } else {
      return <Loading />
    }
  }
}

export default inject('userTeachersFilterStore')(observer(withRouter(withApollo(UserListTeachers))))
